import { useLocationMatch } from '@kaliber/routing'
import { HeroVideo, useAnimations } from '/features/pageOnly/Hero'
import { ButtonGhost } from '/features/buildingBlocks/Button'
import { useTranslate } from '/machinery/I18n'
import { HeadingMd, HeadingXxl } from '/features/buildingBlocks/Heading'
import { routeMap } from '/routeMap'

import styles from './Hero.css'

export default function Hero({ image, video, layoutClassName = undefined }) {
  const { animations, startAnimating } = useAnimations()
  const [titleAnimation, subtitleAnimation, buttonContainerAnimation] = animations

  return (
    <HeroVideo onComponentReady={startAnimating} {...{ image, video, layoutClassName }}>
      <div className={styles.component}>
        <Title animation={titleAnimation} layoutClassName={styles.headingLayout} />
        <Subtitle animation={subtitleAnimation} layoutClassName={styles.subtitleLayout} />
        <ButtonHome animation={buttonContainerAnimation} />
      </div>
    </HeroVideo>
  )
}

function Title({ animation, layoutClassName }) {
  const { __ } = useTranslate()
  return <HeadingXxl h='1' title={__`not-found-title`} {...{ animation, layoutClassName }} />
}

function Subtitle({ layoutClassName, animation }) {
  const { __ } = useTranslate()
  return <HeadingMd h='3' title={__`not-found-subtitle`} {...{ animation, layoutClassName }} />
}

function ButtonHome({ animation }) {
  const { params: { language } } = useLocationMatch()
  const { __ } = useTranslate()

  return (
    <ButtonGhost href={routeMap.app.home({ language })} dataX='link-to-home' {...{ animation }}>
      {__`not-found-label`}
    </ButtonGhost>
  )
}
